import React, { useState } from "react"

import "./main.css"

import Img from "gatsby-image"

const bodyStyle = {
	width: "100%",
	height: "100%",
	margin: "0, 10px",
	fontSize: 24,
}

const headerStyle = {
	paddingTop: 10,
	paddingLeft: 40,
	width: "100%",
	height: 70,
	background: "#eaeaea",
	//background: "#343434",
	position: "relative",
}

const footerStyle = {
	paddingTop: 1,
	paddingLeft: "10%",
	paddingRight: "10%",
	maxWidth: 1600,
	margin: "0 auto",
	width: "100%",
	background: "#eaeaea",
}

const Menu = () => {
	const [open, setOpen] = useState(false)
	return (
		<div
			role={"button"}
			tabIndex={0}
			style={{
				position: "absolute",
				background: "#2f80ed",
				top: 0,
				right: 60,
				height: 80,
				width: 120,
				zIndex: 3,
			}}
			onMouseEnter={() => {
				setOpen(true)
			}}
			onMouseOut={() => {
				setOpen(false)
			}}
			onFocus={() => {
				setOpen(true)
			}}
			onBlur={() => {
				setOpen(false)
			}}
		>
			<div
				style={{
					position: "absolute",
					color: "white",
					width: 120,
					textAlign: "center",
					top: 30,
				}}
			>
				MENU
			</div>
			<div
				style={{
					width: 0,
					height: 0,
					borderLeft: "60px solid transparent",
					borderRight: "60px solid transparent",
					borderTop: "40px solid #2f80ed",
					position: "relative",
					top: 80,
				}}
			></div>

			<div
				style={{
					display: open ? "block" : "none",
				}}
			>
				Open
			</div>
		</div>
	)
}

function PhoneHeader() {
	return (
		<div
			style={{
				position: "absolute",
				top: 20,
				fontSize: "1em",
				textAlign: "right",
				right: 20,
				color: "black",
			}}
		>
			Call us at (801) 946-2015
		</div>
	)
}

export default function Layout({ data, children, mobile }) {
	return (
		<div style={bodyStyle}>
			<div style={headerStyle}>
				<Img alt={"Lone Tree USA Logo"} fixed={data.logoImage.childImageSharp.fixed} />
				<PhoneHeader />
			</div>
			{children}
			<div style={footerStyle}>
				<div className="g1-grid">
					<div style={{ float: "left", width: "30%" }}>
						<section id="text-40" className="widget widget_text g1-widget--cssclass">
							<header>
								<h3 className="widgettitle">Contact Us</h3>
							</header>
							<div className="textwidget">
								<address>
									<strong>Lone Tree USA</strong>
									<br />
									<div>
										2250 West Center St.
										<br />
										Springville, UT 84663
									</div>
								</address>
							</div>
						</section>
					</div>
					<div style={{ float: "left", width: "70%" }}>
						<section id="text-40" className="widget widget_text g1-widget--cssclass">
							<div
								className="textwidget"
								style={{
									paddingTop: 40,
								}}
							>
								<span>
									<strong>Phone:</strong> (801) 946-2015
									<br />
									<span>
										<strong>Email: </strong>
										<a href="mailto:josh@LoneTreeUSA.com">josh@LoneTreeUSA.com</a>
									</span>
								</span>
							</div>
						</section>
					</div>
				</div>
			</div>
		</div>
	)
}
