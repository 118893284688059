import React, { useEffect, useState } from "react"
import Header from "../components/header"
import Layout from "../components/layout"

import { graphql } from "gatsby"
import Img from "gatsby-image"
import HoverImage from "../components/hoverImage"
import RotateImage from "../components/rotateImage"

import coverImg1 from "../images/CondoNoPole.jpg"
import coverImg2 from "../images/storage.jpg"
import coverImg3 from "../images/dreamstime_m_33792412.jpg"
import * as PropTypes from "prop-types"
import Helmet from "react-helmet"

import ContactForm from "../components/ContactForm"

const contentStyle = {
	maxWidth: 1600,
	margin: "0 auto",
	paddingLeft: "10%",
	paddingRight: "10%",
}

const gridStyle = {
	display: "flex",
	justifyContent: "space-between",
	flexWrap: "wrap",
	margin: "-10px",
}

const mainBoxStyle = {
	position: "relative",
	margin: "10px",
}

const thirdGridStyle = {
	display: "flex",
	justifyContent: "space-between",
	flexWrap: "wrap",
	margin: "20px",
}

const thirdBoxStyle = {
	...mainBoxStyle,
	width: "calc(33.3% - 40px)",
}

function HoverImages(props) {
	return (
		<div style={contentStyle}>
			<div style={gridStyle}>
				<HoverImage
					fluidImg={props.marketingImage.childImageSharp.fluid}
					text={"Marketing"}
					idx={1}
					onHoverFn={() => props.onClick("marketing")}
				/>
				<HoverImage
					fluidImg={props.maintImage.childImageSharp.fluid}
					text={"Maintenance"}
					hoverInvert={true}
					idx={2}
					onHoverFn={() => props.onClick("maint")}
				/>
				<HoverImage
					fluidImg={props.legalImage.childImageSharp.fluid}
					text={"Tenant Services"}
					idx={3}
					onHoverFn={() => props.onClick("tenant")}
				/>
				<HoverImage
					fluidImg={props.accountingImage.childImageSharp.fluid}
					text={"Accounting"}
					idx={4}
					onHoverFn={() => props.onClick("accounting")}
				/>
			</div>
		</div>
	)
}

HoverImages.propTypes = {
	marketingImage: PropTypes.any,
	maintImage: PropTypes.any,
	legalImage: PropTypes.any,
	accountingImage: PropTypes.any,
}

function Marketing(props) {
	const { type, mobile } = props

	let title = ""
	let message = ""
	let listTitle = ""
	let list = []
	let images = <div></div>

	switch (type) {
		case "marketing":
			title = "A Full Service Provider"
			message = (
				<div>
					<div>
						At Lone Tree Facility Management, we are partnering with customers to manage and maintain the entire
						facility. We asses the rent-ready needs for the property, post "for rent" signs, and add the listing to all
						the major rental sites.
					</div>
					<br />
					<div style={thirdGridStyle}>
						<div style={thirdBoxStyle}>
							<Img fluid={{ ...props.zillowImage.childImageSharp.fluid }} />
						</div>
						<div style={thirdBoxStyle}>
							<Img fluid={{ ...props.rentImage.childImageSharp.fluid }} />
						</div>
						<div style={thirdBoxStyle}>
							<Img fluid={{ ...props.apartmentsImage.childImageSharp.fluid }} />
						</div>
						<div style={thirdBoxStyle}>
							<Img fluid={{ ...props.hotPadsImage.childImageSharp.fluid }} />
						</div>
						<div style={thirdBoxStyle}>
							<Img fluid={{ ...props.truliaImage.childImageSharp.fluid }} />
						</div>
						<div style={thirdBoxStyle}>
							<Img fluid={{ ...props.myNewPlace.childImageSharp.fluid }} />
						</div>
					</div>
				</div>
			)

			listTitle = "Marketing Services"
			list = [
				"Professionally photograph property",
				"Marketing Ads on rental websites, individual website, Local classifieds",
				"Property showings to prospective tenants",
				"Applications, contracting and communications to all tenants",
				"Background, Credit check, Income Verification, and rental history on all tenants",
			]

			break
		case "maint":
			title = "Maintenance and Inspections"
			message = (
				<div>
					We maintain the facility as if it were our own. With thorough and routine inspections, we find issues before
					they become problems keeping tenants happy and comfortable.
					<br />
					<br />
				</div>
			)

			listTitle = "Maintenance Services"
			list = [
				"Conduct thorough move-in and move- out inspections",
				"Coordinate rent ready maintenance",
				"Resolve all maintenance requests",
				"Violation management",
				"Seasonal maintenance as needed",
				"Coordinate all repairs and remodels",
				"Provide a 24/7 emergency line for residents",
				"Coordinate and oversee all repairs and remodels",
			]

			break
		case "tenant":
			title = "Tenant Services"
			message = (
				<div>
					Our management team handles the responsibility and oversight of finding and keeping the tenants. From
					background checks, contracts and even evictions, we will manage everything the tenant and facility needs.
					<br />
					<br />
				</div>
			)
			listTitle = "Tenant Services"
			list = [
				"Leases, applications, and showings",
				"Background Checks",
				"Screening against nationwide evictions database",
				"Oversee Evictions",
			]
			break
		case "accounting":
			title = "Accounting Services"
			message = (
				<div>
					Payments are collected, processed, and put into your account on your behalf. We handle expense tracking,
					facility budgets and all accounting activity related to your property.
					<br />
					<br />
				</div>
			)
			listTitle = "Tenant Services"
			list = [
				"Conduct thorough move-in and move- out inspections",
				"Coordinate rent ready maintenance",
				"Resolve all maintenance requests",
				"Violation management",
				"Seasonal maintenance as needed",
				"Coordinate all repairs and remodels",
				"Provide a 24/7 emergency line for residents",
				"Coordinate and oversee all repairs and remodels",
			]
			break
	}

	const half = Math.ceil(list.length / 2)
	return (
		<div style={{ background: "#DFF3E7" }}>
			<div className={"info marketing"} style={{ ...contentStyle, display: "flex" }}>
				<div style={{ width: "50%", padding: "0 40px 0 0" }}>
					<h2>{title}</h2>
					<div>{message}</div>
					{!mobile && (
						<div style={{ textAlign: "center", width: "100%", paddingTop: 20 }}>
							<a
								style={{
									width: 400,
									textAlign: "center",
									background: "#2D9CDB",
									color: "white",
									padding: "15px 20px",
									margin: "0 auto",
									textShadow: "none",
								}}
								href={"mailto:josh@LoneTreeUSA.com"}
							>
								Let us help you!
							</a>
						</div>
					)}
					<br />
					<br />
				</div>
				<div
					style={{
						width: "50%",
						background: "#4F4F4F",
						color: "white",
						padding: "20px 10px",
						margin: "-25px 0",
					}}
				>
					<div style={{ textAlign: "center", color: "white", fontSize: "1.5em" }}>{listTitle}</div>
					{mobile && <br />}
					<ul style={{ paddingLeft: 20 }}>
						{list.map(l => (
							<li>{l}</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	)
}

Marketing.propTypes = {
	zillowImage: PropTypes.any,
	rentImage: PropTypes.any,
	apartmentsImage: PropTypes.any,
	hotPadsImage: PropTypes.any,
	truliaImage: PropTypes.any,
	myNewPlace: PropTypes.any,
}
export default function Home({ data }) {
	const initTextStyle = {
		position: "absolute",
		top: 100,
		zIndex: 1,
		width: "45%",
		textAlign: "right",
		lineHeight: "1.3em",
		color: "white",
		textShadow: "2px 2px #949191",
	}

	const [textStyle, setTextStyle] = useState(initTextStyle)
	const [visibleTab, setVisibleTab] = useState("marketing")
	const [mobile, setIsMobile] = useState(true)

	useEffect(() => {
		const setWidth = width => {
			if (width > 1950) {
				setTextStyle({
					...textStyle,
					fontSize: "3.0em",
				})
				setIsMobile(false)
				return
			}

			setIsMobile(true)
			if (width > 1700) {
				setTextStyle({
					...textStyle,
					fontSize: "3.0em",
				})
				return
			}

			if (width > 1400) {
				setTextStyle({
					...textStyle,
					fontSize: "2.2em",
				})
				return
			}

			if (width > 1200) {
				setTextStyle({
					...textStyle,
					fontSize: "1.8em",
				})
				return
			}

			setTextStyle({
				...textStyle,
				fontSize: "1em",
			})
			return
		}

		setWidth(window.innerWidth)
		window.addEventListener("resize", () => {
			setWidth(window.innerWidth)
		})
		return () => {
			window.removeEventListener("resize", () => {})
		}
	}, [])

	return (
		<Layout data={data} mobile={mobile}>
			<Helmet>
				<title>Lone Tree Facility Management</title>
			</Helmet>
			<div>
				<div style={{ clear: "both" }}>
					{/*<Img sizes={{ ...data.coverImage.childImageSharp.fluid, aspectRatio: 3 / 1 }} />*/}
					<div
						style={{
							width: "100%",
							height: 450,
						}}
					>
						<RotateImage
							images={[
								{ title: "Apartment Buildings", img: coverImg1 },
								{ title: "Storage Units", img: coverImg2 },
								{ title: "Mobile Communities", img: coverImg3 },
							]}
							duration={5}
							transition={2}
						/>
					</div>
				</div>
				<div style={{ clear: "both" }}></div>
				<br />
				<div style={contentStyle}>
					<Header>The Complete Facility Management Solution</Header>
					<div>
						Lone Tree Facility Management helps you simplify ownership of your residential and commercial facilities.
						Everything from marketing the rental to routine maintenance is handled by our professional management team.
						We will find the tenants, manage the accounting, and keep the facility in rent-ready condition.
						<br />
						<br />
						Sit back, relax, and make money on your investment.
					</div>
				</div>
				<br />
				{!mobile && (
					<HoverImages
						marketingImage={data.marketingImage}
						maintImage={data.maintImage}
						legalImage={data.legalImage}
						accountingImage={data.accountingImage}
						onClick={t => setVisibleTab(t)}
					/>
				)}
				<br />
				<br />
				<br />
				{!mobile && (
					<Marketing
						type={visibleTab}
						zillowImage={data.zillowImage}
						rentImage={data.rentImage}
						apartmentsImage={data.apartmentsImage}
						hotPadsImage={data.hotPadsImage}
						truliaImage={data.truliaImage}
						myNewPlace={data.myNewPlace}
						mobile={mobile}
					/>
				)}
				{mobile && (
					<div>
						<Marketing
							type={"marketing"}
							zillowImage={data.zillowImage}
							rentImage={data.rentImage}
							apartmentsImage={data.apartmentsImage}
							hotPadsImage={data.hotPadsImage}
							truliaImage={data.truliaImage}
							myNewPlace={data.myNewPlace}
							mobile={mobile}
						/>
						<br />
						<br />
						<br />
						<br />
						<Marketing
							type={"maint"}
							zillowImage={data.zillowImage}
							rentImage={data.rentImage}
							apartmentsImage={data.apartmentsImage}
							hotPadsImage={data.hotPadsImage}
							truliaImage={data.truliaImage}
							myNewPlace={data.myNewPlace}
							mobile={mobile}
						/>
						<br />
						<br />
						<br />
						<br />
						<Marketing
							type={"tenant"}
							zillowImage={data.zillowImage}
							rentImage={data.rentImage}
							apartmentsImage={data.apartmentsImage}
							hotPadsImage={data.hotPadsImage}
							truliaImage={data.truliaImage}
							myNewPlace={data.myNewPlace}
							mobile={mobile}
						/>
						<br />
						<br />
						<br />
						<br />
						<Marketing
							type={"accounting"}
							zillowImage={data.zillowImage}
							rentImage={data.rentImage}
							apartmentsImage={data.apartmentsImage}
							hotPadsImage={data.hotPadsImage}
							truliaImage={data.truliaImage}
							myNewPlace={data.myNewPlace}
							mobile={mobile}
						/>
					</div>
				)}

				<br />
				<br />
				<br />
				<br />
			</div>
		</Layout>
	)
}
//
export const query = graphql`
	query {
		coverImage: file(relativePath: { eq: "apartment.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 2000, quality: 100, maxHeight: 650) {
					...GatsbyImageSharpFluid
				}
			}
		}
		coverImage1: file(relativePath: { eq: "apartment.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 2000, quality: 100, maxHeight: 650) {
					...GatsbyImageSharpFluid
				}
			}
		}
		coverImage2: file(relativePath: { eq: "apartment.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 2000, quality: 100, maxHeight: 650) {
					...GatsbyImageSharpFluid
				}
			}
		}
		marketingImage: file(relativePath: { eq: "marketing.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 600, quality: 100, maxHeight: 800) {
					...GatsbyImageSharpFluid
				}
			}
		}
		maintImage: file(relativePath: { eq: "maint.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 600, quality: 100, maxHeight: 800) {
					...GatsbyImageSharpFluid
				}
			}
		}
		legalImage: file(relativePath: { eq: "door.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 600, quality: 100, maxHeight: 800) {
					...GatsbyImageSharpFluid
				}
			}
		}
		accountingImage: file(relativePath: { eq: "accounting.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 600, quality: 100, maxHeight: 800) {
					...GatsbyImageSharpFluid
				}
			}
		}
		logoImage: file(relativePath: { eq: "Lonetree_Logo.png" }) {
			childImageSharp {
				fixed(height: 52) {
					...GatsbyImageSharpFixed
				}
			}
		}

		zillowImage: file(relativePath: { eq: "Zillow_logo_blue.png" }) {
			childImageSharp {
				fluid(maxWidth: 200, quality: 100, maxHeight: 200, fit: CONTAIN, background: "#0074e4") {
					...GatsbyImageSharpFluid
				}
			}
		}
		rentImage: file(relativePath: { eq: "rentcom.png" }) {
			childImageSharp {
				fluid(maxWidth: 200, quality: 100, maxHeight: 200, fit: CONTAIN, background: "#ffffff") {
					...GatsbyImageSharpFluid
				}
			}
		}
		apartmentsImage: file(relativePath: { eq: "apartments.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 200, quality: 100, maxHeight: 200, fit: CONTAIN, background: "#ffffff") {
					...GatsbyImageSharpFluid
				}
			}
		}
		hotPadsImage: file(relativePath: { eq: "hotpads.png" }) {
			childImageSharp {
				fluid(maxWidth: 200, quality: 100, maxHeight: 200, fit: CONTAIN, background: "#dff3e7") {
					...GatsbyImageSharpFluid
				}
			}
		}
		truliaImage: file(relativePath: { eq: "trulia.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 200, quality: 100, maxHeight: 200, fit: CONTAIN, background: "#ffffff") {
					...GatsbyImageSharpFluid
				}
			}
		}
		myNewPlace: file(relativePath: { eq: "mynewplace.png" }) {
			childImageSharp {
				fluid(maxWidth: 200, quality: 100, maxHeight: 200, fit: CONTAIN, background: "#ffffff") {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`
